// TEMP FOR TESTING
import data from './data';

const users = [
    {
        id : 0,
        username : "Maxime",
        label : "CPR",
        email : "maxime@email.com",
        password : "password"
    },
    {
        id : 2,
        username : "AirFrance",
        label : "AIR",
        email : "airfrance@email.com",
        password : "password"
    },
    {
        id : 3,
        username : "VapiVapou",
        label : "VAP",
        email : "vapivapou@email.com",
        password : "password"
    }

];

const authentification = (login, password) => {
    const user = users.find(el => el.username.toLowerCase() === login.toLowerCase());
    if (user && user.password === password) {
        return data(user)
    } else {
        return {status : 400}
    }
    
}

export const isAuthenticated = () => {
    return false
}

export default authentification