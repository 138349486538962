import React from 'react';
import filterByYear from '../utils/filterByYear';
import file_download from '../assets/file-download-solid.svg'; 

import './Files.css';



function Files({ content, year }) {


    const handleClickFile = (url) => {
        alert('will download ' +url)
    }

    return (
        <ul className="Files">

            {filterByYear(content).map((el, i) => {
                return (
                <li key={i} onClick={() => handleClickFile(el.url)} hidden={((year !== el.year) && (year !== 'all'))}>
                    {el.filename.replace('.pdf',"")} 
                    <i>{el.date}</i>
                    <img src={file_download} alt=""/>
                </li>)
            })}
        </ul>
    )
}

export default Files
