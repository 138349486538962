// TEMP FOR TESTING

const results = {
    CPR : {
        name : "Contrôle Périodique Règlementaire",
        invoices : [],
        reports : [],
    },
    AIR : {
        name : "Air France",
        invoices: [
            {
                filename: "facture 001 airFake.pdf",
                date: "2020-01-01",
                url: "#"
            },
            {
                filename: "facture 002 airFake.pdf",
                date: "2020-02-01",
                url: "#"
            },
            {
                filename: "facture 003 airFake.pdf",
                date: "2020-03-01",
                url: "#"
            },
            {
                filename: "facture 004 airFake.pdf",
                date: "2020-04-01",
                url: "#"
            },
            {
                filename: "facture 005 airFake.pdf",
                date: "2020-05-01",
                url: "#"
            },
            {
                filename: "facture 006 airFake.pdf",
                date: "2020-06-01",
                url: "#"
            },
            {
                filename: "facture 007 airFake.pdf",
                date: "2020-07-01",
                url: "#"
            },
            {
                filename: "facture 008 airFake.pdf",
                date: "2020-08-01",
                url: "#"
            },
            {
                filename: "facture 009 airFake.pdf",
                date: "2020-09-01",
                url: "#"
            },
            {
                filename: "facture 010 airFake.pdf",
                date: "2020-10-01",
                url: "#"
            },
            {
                filename: "facture 011 airFake.pdf",
                date: "2020-11-01",
                url: "#"
            },
            {
                filename: "facture 012 airFake.pdf",
                date: "2020-12-01",
                url: "#"
            },
            {
                filename: "facture 013 airFake.pdf",
                date: "2021-01-01",
                url: "#"
            },
            {
                filename: "facture 000 airFake.pdf",
                date: "2018-01-01",
                url: "#"
            }
        ],
        reports: [
            {
                filename: "Controle des extincteurs.pdf",
                date: "2020-01-01",
                url: "#"
            },
            {
                filename: "Controle de la machine à café.pdf",
                date: "2020-02-01",
                url: "#"
            },
            {
                filename: "Inspection mensuelle.pdf",
                date: "2020-03-01",
                url: "#"
            },
            {
                filename: "Audit général.pdf",
                date: "2021-04-01",
                url: "#"
            }, {
                filename: "Ancienne documentation.pdf",
                date: "2019-04-01",
                url: "#"
            }
        ]
    },
    VAP :{
        name :" Vape Nation",
        invoices: [
            {
                filename: "facture 001 VapiVapou.pdf",
                date: "2021-01-01",
                url: "#"
            },
            {
                filename: "facture 002 VapiVapou.pdf",
                date: "2022-02-01",
                url: "#"
            },
            {
                filename: "facture 003 VapiVapou.pdf",
                date: "2023-03-01",
                url: "#"
            },
            {
                filename: "facture 004 VapiVapou.pdf",
                date: "2024-04-01",
                url: "#"
            },
            {
                filename: "facture 005 VapiVapou.pdf",
                date: "2025-05-01",
                url: "#"
            },
            {
                filename: "facture 006 VapiVapou.pdf",
                date: "2020-06-01",
                url: "#"
            },
            {
                filename: "facture 007 VapiVapou.pdf",
                date: "2020-07-01",
                url: "#"
            },
            {
                filename: "facture 008 VapiVapou.pdf",
                date: "2020-08-01",
                url: "#"
            },
            {
                filename: "facture 009 VapiVapou.pdf",
                date: "2020-09-01",
                url: "#"
            },
            {
                filename: "facture 010 VapiVapou.pdf",
                date: "2020-10-01",
                url: "#"
            },
            {
                filename: "facture 011 VapiVapou.pdf",
                date: "2020-11-01",
                url: "#"
            },
            {
                filename: "facture 012 VapiVapou.pdf",
                date: "2020-12-01",
                url: "#"
            },
            {
                filename: "facture 013 VapiVapou.pdf",
                date: "2021-01-01",
                url: "#"
            },
            {
                filename: "facture 000 VapiVapou.pdf",
                date: "2018-01-01",
                url: "#"
            }
        ],
        reports: [
            {
                filename: "Controle des vapoteuses.pdf",
                date: "2020-01-01",
                url: "#"
            },
            {
                filename: "Controle de la machine à vapoter.pdf",
                date: "2020-02-01",
                url: "#"
            },
            {
                filename: "Vapoteuse mensuelle.pdf",
                date: "2020-03-01",
                url: "#"
            },
            {
                filename: "Audit à vapeur général.pdf",
                date: "2021-04-01",
                url: "#"
            }, {
                filename: "Anciennes documentations.pdf",
                date: "2019-04-01",
                url: "#"
            }
        ]
    },
};



const getData = (user) => {
    if (user.label === "CPR") {
        return {result : results, admin : true, status : 200, user }
    } else {
        return { result : { [user.label] :results[user.label]}, status : 200, user }
    }
}

export const dummyData = {
    result : {DUM : results.VAP }, admin : false, user : {username: "dummyUser", email : "dummy@email.com", label : "DUM"}
}

export default getData