import React from 'react';
import logo from '../assets/cpr_logo.png';

function Header() {
    return (
        <div className="Header">
            <img src={logo} alt="Contrôle Périodique Règlementaire" />
            <h2>Factures et rapports</h2>
        </div>
    )
}

export default Header
