const filterByYear = (files) => {
    let newFiles = files.map((file) => {
        file.parsedDate = Date.parse(file.date)
        file.year = file.date[0]+file.date[1]+file.date[2]+file.date[3]
        return file
    })
    newFiles = newFiles.sort((a,b) => b.parsedDate - a.parsedDate )
    return newFiles
}

export default filterByYear