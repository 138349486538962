import React, { useState, useEffect } from 'react';

// MY MODULES
import Files from './Files';
import Header from './Header';
import Modal from './modules/Modal';
import PasswordChange from './PasswordChange';

// UTILS
// import {isAuthenticated} from '../utils/authentification';
import getYears, { currentYear } from '../utils/getYears';

// ASSETS
import icon_invoices from '../assets/file-invoice-dollar-solid.svg';
import icon_reports from '../assets/file-invoice-solid.svg';
import icon_cog from '../assets/cog-solid.svg';

import './Panel.css';

function Panel({ adminPanel, data, logout }) {

    const [label, setLabel] = useState(data.user.label || "DUM")
    const [content, setContent] = useState('reports');

    const [selectedYear, setSelectedYear] = useState(currentYear);

    const [modalVisible, setModalVisible] = useState(false)


    useEffect(() => {
        // if (!isAuthenticated()) logout()
        setLabel(data.user.label);

    }, [data,logout])

    const handleClick = (el) => {
        setContent(el);
    }
    const handleChange = (e) => {
        // console.log(e.target.value)
        setLabel(e.target.value);
    }

    const handleClickYear = (e) => {
        setSelectedYear(e.target.value);
    }

    const handleModalVisible = () => {
        setModalVisible(!modalVisible);
    }

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
    }

    return (
        <div className="Panel">
            <Header />
            <h2>{data.user.username}</h2>
            <div className="profileButton" onClick={handleModalVisible}><span><img src={icon_cog} alt="menu utilisateur"/></span> Paramètres utilisateur</div> 
            <div className="admin" hidden={!adminPanel}>
                <select onChange={handleChange}>
                    {Object.keys(data.result).map((res, i) => <option value={res} key={i}>{res} - {data.result[res].name}</option>)}
                </select>
            </div>
            <div className="content">
                <div className="buttons">
                    <button className={(content === 'reports') ? "left selected" : "left"} onClick={() => handleClick('reports')}>Rapports <img src={icon_reports} alt="" /></button>
                    <button className={(content === 'invoices') ? "right selected" : "right"} onClick={() => handleClick('invoices')}><img src={icon_invoices} alt="" /> Factures</button>

                    <div className="years">
                        <button
                            value="all"
                            onClick={handleClickYear}
                            className={('all' === selectedYear ? 'selected' : "")}
                        >
                            Tout
                        </button>
                        {getYears(data.result[label][content]).map((year, i) => (
                            <button
                                key={i}
                                value={year}
                                onClick={handleClickYear}
                                className={(year === selectedYear ? 'selected' : "")}>
                                {year}
                            </button>))}
                    </div>
                </div>
                <Files content={data.result[label][content]} year={selectedYear} />

            </div>
            <Modal
                isVisible={modalVisible}
                setIsVisible={handleModalVisible}
                hiddenPosition={'right'}
                innerBackgroundColor={'#291f1f'}
            >
                <div className="logout">
                    <button onClick={handleLogout}>Se déconnecter</button>
                </div>
                <PasswordChange/>
            </Modal>
        </div>
    )
}

export default Panel
