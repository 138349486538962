import React, {useState, useEffect} from 'react';

import './Modal.css';


// isVisible (mandatory) : true/false to show and hide the Modal
// hiddenPosition : center, left, right, top, bottom
// 
function Modal({isVisible, setIsVisible,hiddenPosition = 'center', innerBackgroundColor = 'grey', fontColor = "white", children}) {

    const [classNames, setClassnames] = useState('Modal '+hiddenPosition+ ((isVisible)?' visible': ' hidden'))
    
    useEffect(() => {
        setClassnames('Modal '+hiddenPosition+ ((isVisible)?' visible': ' hidden'))
    },[isVisible, hiddenPosition])

    const handleClick = () => {
        setIsVisible()
    }

    return (
        <div className={classNames}>
            <div style={{backgroundColor : innerBackgroundColor, color : fontColor}}>
                <button onClick={handleClick} className='close'> </button>
                {children}
            </div>
        </div>
    )
}

export default Modal
