import React, {useState} from 'react'

import Header from './Header';
import Modal from './modules/Modal';
import ForgottenId from './ForgottenId';

import authentification from '../utils/authentification';


import './Login.css';

function Login({handleLogin}) {
    const [form, setForm] = useState({username : "", password : ""});
    const [isValid, setIsValid] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);

    const handleChange = (e) => {
        let newForm = form
        form[e.target.id] = e.target.value;
        isFormValid()
        setForm(newForm);
    }

    const isFormValid = () => {
        const result = (form.username.length > 2 && form.password.length > 5);
        setIsValid(result);
        return result;
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleConnect()
    }

    const handleClick = (e) => {
        e.preventDefault();
        handleConnect()
    }
    const handleConnect = async () => {
        if (isValid) {
            let response = await authentification(form.username, form.password)
            if (response.status === 200) {
                handleLogin({...response})
            } else if (response.status === 400) {
                alert('Votre mot de passe et/ou votre nom d\'utilisateur est incorrect.')
            } else {
                console.log('an error occured')
            }
        } else {
            alert('Merci d\'entrer vos identifiants')
        }
    }

    const handleForgotten = (e) => {
        e.preventDefault();
        handleModalVisible();
    }

    const handleModalVisible = () => {
        setModalVisible(!isModalVisible);
    }

    return (
        <div>
            <div className="login-box">
                <div>
                    <Header/>
                </div>

                <form>
                    <div className="user-box">
                        <input type="text" name="" required="" id="username" onChange={handleChange}  onKeyPress={handleKeyPress}/>
                        <label htmlFor="username">Nom d'utilisateur</label>

                    </div>
                    <div className="user-box">
                        <input type="password" name="" required="" id="password" onChange={handleChange}  onKeyPress={handleKeyPress}/>
                        <label htmlFor="password">Mot de passe</label>

                    </div>
                    <button id="forgottenPassword" onClick={handleForgotten}>Identifiant(s) oublié(s) ?</button>
                    <button id="btn-connect" onClick={handleClick}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Se connecter
                    </button>
                </form>
            </div>
            <Modal
                isVisible={isModalVisible}
                setIsVisible={handleModalVisible}
                hiddenPosition={'left'}
                innerBackgroundColor={'#291f1f'}
            >
                <ForgottenId/>
            </Modal>
        </div>
    )
}

export default Login
