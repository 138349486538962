import React, {useState} from 'react';

import './PasswordChange.css';

function ForgottenId() {

    const [id, setId] = useState('')

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSubmit()
    }

    const handleChange = (e) => {
        setId(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id.length < 2) alert('Veuillez entrer un identifiant ou une adresse email')
        else alert('Procédure de retrouvaille en cours pour : '+id)
    }

    return (
        <div className="ForgottenId">
            <b>Identifiants oubliés ?</b>
            <form>
                <label htmlFor="identifier">
                    Entrez votre adresse email ou votre identifiant <br/><br/>
                    <input type="text" id="identifier" placeholder="identifiant ou email" onChange={handleChange} onKeyPress={handleKeyPress}/>
                </label>
                <br/>
                <button className="submit" onClick={handleSubmit}>Valider</button>
            </form>
        </div>
    )
}

export default ForgottenId
