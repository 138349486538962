import React from 'react';
import eye_regular from '../assets/eye-regular.svg';
import eye_slash_regular from '../assets/eye-slash-regular.svg';


const styles = {
    button : {
        width : '25px',
        height : '25px',
        margin : '0',
        padding : '0',
        background : 'none',
        border: 'none',
        position : 'absolute',
        right : '0',
        cursor : 'pointer',
        filter : 'invert(1)'
        
    },
    img : {
        margin : '0',
        padding : '0',
        height : '23px',
        width : '23px',
    }
}

function TogglePassword({isToggle, setToggle}) {
    return (
        <button 
            className="TogglePassword" 
            style={styles.button}
            onClick={setToggle}>
                <img 
                src={(isToggle)? eye_regular : eye_slash_regular} 
                alt=""/>
        </button>
    )
}

export default TogglePassword
