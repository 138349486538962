import React, {useState} from 'react'
// import {Routes, Route, useNavigate} from 'react-router-dom';

// UTILS
// import {isAuthenticated} from './utils/authentification';
import {dummyData} from './utils/data';

import Login from './components/Login';
import Panel from './components/Panel';

import './App.css';

function App() {
  const [isLogged, setIsLogged] = useState(false)
  const [adminPanel, setAdminPanel] = useState(false)
  const [data, setData] = useState(dummyData)

  // const navigate = useNavigate()

  const handleLogin = async (response) => {
    console.log(response.status)
    if (response.admin) setAdminPanel(response.admin)
    setData(response)
    if (response.status === 200) setIsLogged(true)
    // navigate("/panel")
  }

  const handleLogout = () => {
    if (localStorage.getItem('token')) localStorage.removeItem('token')
    alert("Vous avez été déconnecté.");
    window.location.reload()
    // navigate("/")
  }

  return (
    <div className="App">
      {/* <Routes>
        <Route path="" element={<Login handleLogin={handleLogin}/>} />
        <Route path="panel" element={<Panel adminPanel={adminPanel} data={data} logout={handleLogout}/>} />
      </Routes> */}
      {(!isLogged) ? <Login handleLogin={handleLogin}/> : <Panel adminPanel={adminPanel} data={data} logout={handleLogout}/>} 
    </div>
  );
}

export default App;
