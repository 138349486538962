import React, {useState} from 'react';
import TogglePassword from './TogglePassword';

import './PasswordChange.css';

function PasswordChange() {

    const [oldPasswordShown, setOldPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);

    const [formData, setFormData] = useState({oldPassword :"", newPassword: "", newPasswordBis: ""})

    const toggleOldPassword = (e) => {
        e.preventDefault();
        setOldPasswordShown(!oldPasswordShown);
    };
    const toggleNewPassword = (e) => {
        e.preventDefault();
        setNewPasswordShown(!newPasswordShown);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSubmit()
    }

    const handleChange = (e) => {
        e.preventDefault();
        let newValue = formData;
        newValue[e.target.id] = e.target.value;
        setFormData(newValue);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.newPassword.length < 5) alert('Veuillez entrer un mot de passe valide')
        else if (formData.newPassword !== formData.newPasswordBis) alert('Votre mot de passe et la confirmation ne correspondent pas.')
        else alert('Changement de mot de passe.')
    }


    return (
        <div className="PasswordChange">
            <b>Modifier votre mot de passe</b>
            <form>
                <label htmlFor="oldPassword">
                    Entrez votre mot de passe actuel
                    <input type={(oldPasswordShown)?'text':'password'} id="oldPassword" onChange={handleChange} onKeyPress={handleKeyPress}/>
                    <TogglePassword setToggle={toggleOldPassword} isToggle={oldPasswordShown}/>
                </label>
                <label htmlFor="newPassword">
                    Entrez votre nouveau mot de passe
                    <input type={(newPasswordShown)?'text':'password'} id="newPassword" onChange={handleChange} onKeyPress={handleKeyPress}/>
                    <TogglePassword setToggle={toggleNewPassword} isToggle={newPasswordShown}/>
                </label>

                <label htmlFor="newPasswordBis">
                    Confirmez votre nouveau mot de passe
                    <input type={(newPasswordShown)?'text':'password'} id="newPasswordBis" onChange={handleChange} onKeyPress={handleKeyPress}/>
                    <TogglePassword setToggle={toggleNewPassword} isToggle={newPasswordShown}/>
                </label>
                <button className='submit' onClick={handleSubmit}>Valider</button>
            </form>
        </div>
    )
}

export default PasswordChange
